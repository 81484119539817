<template>
    <div>
        <h2>{{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}ช่องทางการชำระ</h2>
        <v-form v-model="isFormValid">
          <v-card class="mt-4">
            <v-card-title>ประเภทช่องทาง</v-card-title>

            <div class="pa-8">
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    :items="items"
                    label="ประเภทช่องทางการชำระ"
                    outlined
                    v-model="formData.type"
                    item-text="name"
                    item-value="id"
                    dense
                    :disabled="$store.getters['app/GET_APP_MODE'] != 'create'"
                    hide-details="auto"
                  >                    
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.name"
                      outlined
                      label="ชื่อช่องทาง"                               
                      :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                      hide-details="auto"          
                      dense  
                    ></v-text-field>
                  </v-col>
                </v-row>
            </div>

            <v-card-actions>              
              <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid"
                :loading="sendingData"
                @click="submitForm"
                v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
              >
                {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
              </v-btn>

              <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
              >
                กลับ
              </v-btn>                
            </v-card-actions>
          </v-card>
        </v-form>
    </div>
</template>
<script>
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'
import { maxLengthValidator, required } from '@core/utils/validation'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      items: [
        {
          id: 'transfer',
          name: 'โอน',
        },
        {
          id: 'credit_full',
          name: 'เครดิต เต็ม',
        },
        {
          id: 'credit_installment',
          name: 'เครดิต ผ่อน',
        },
      ],
      formData: {
        type: 'transfer',
        name: null,
      },
      validators: {
        required,
        maxLengthValidator,
      },
    }
  },
  async created() {
    const pospayment_id = this.$route.params.pospayment_id

    if (pospayment_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Setting'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/possetting/payment/' + pospayment_id)

      this.formData = {
        ...response,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        const sendData = { ...this.formData }

        const pospayment_id = this.$route.params.pospayment_id

        if (pospayment_id == 'create') await asyncPostAsJson('/possetting/payment', sendData)
        else {
          sendData['_method'] = 'PUT'
          await asyncPostAsJson('/possetting/payment/' + pospayment_id, sendData)
        }

        this.$router.push({ name: 'pospayment' })
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.sendingData = false
    },
  },
  components: {},
}
</script>